
 @font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-Light.ttf')
      format('truetype');
    font-style: normal;
    font-weight: 200;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-Regular.ttf')
      format('truetype');
    font-style: normal;
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-SemiBold.ttf')
      format('truetype');
    font-style: normal;
    font-weight: 600;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-Bold.ttf')
      format('truetype');
    font-style: normal;
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-ExtraBold.ttf')
      format('truetype');
    font-style: normal;
    font-weight: 800;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-LightItalic.ttf')
      format('truetype');
    font-style: italic;
    font-weight: 200;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-Italic.ttf')
      format('truetype');
    font-style: italic;
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-SemiBoldItalic.ttf')
      format('truetype');
    font-style: italic;
    font-weight: 600;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-BoldItalic.ttf')
      format('truetype');
    font-style: italic;
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-ExtraBoldItalic.ttf')
      format('truetype');
    font-style: italic;
    font-weight: 800;
  }
  